import { Grid, Tooltip } from '@components';
import { PageOptions, SortOptions } from '@components/shared';
import { createElement, stringifyParams } from '@utils';
import consumer from '../channels/consumer';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = [
        'gridContainer',
        'search',
        'searchColumn',
        'searchPredicate',
        'searchValue',
        'applyButton',
        'clearButton'
    ];

    declare gridContainerTarget: HTMLElement;
    declare searchTarget: HTMLInputElement;
    declare searchColumnTarget: HTMLSelectElement;
    declare searchPredicateTarget: HTMLSelectElement;
    declare searchValueTarget: HTMLElement;
    declare applyButtonTarget: HTMLElement;
    declare clearButtonTarget: HTMLElement;

    static values = {
        confirmActionFormId: String,
        url: {
            type: String,
            default: '',
        },
        organizationId: {
            type: String,
            default: ''
        }
    };

    declare urlValue: string;
    declare organizationIdValue: string;

    private grid: Grid<RunLog>;
    private parmsObj: ParamsObj = {};
    private disabled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private runLogsUpdatesSubscription: any;

    private columnPredicateMap = {
        loggable_id: ['eq'],
        name: ['i_cont', 'i_cont_any', 'i_cont_all']
    };

    connect(): void {
        this.parmsObj.page = {
            size: 30,
            number: 1
        };

        this.prefillSearchFieldsFromUrl();
        this.createGrid();
        if (this.organizationIdValue) {
            this.runLogsUpdatesSubscription = consumer.subscriptions.create(
                {
                    channel: 'JemRunLogsGridChannel',
                    organization_id: this.organizationIdValue,
                },
                {
                    received: this.handleRowUpdates,
                }
            );
        }
        this.buildQueryAndReload();
    }

    private prefillSearchFieldsFromUrl(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const filterParams = Array.from(urlParams.entries()).find(([key]) => key.startsWith('filter['));
        
        if (filterParams) {
            const [filterKey, filterValue] = filterParams;
            const [column, predicate] = this.extractColumnAndPredicatefilter(filterKey);
            
            if (this.columnPredicateMap[column]) {
                this.searchColumnTarget.value = column;
                this.updatePredicates();
                
                if (this.columnPredicateMap[column].includes(predicate)) {
                    this.searchPredicateTarget.value = predicate;
                } else {
                    this.searchPredicateTarget.value = this.columnPredicateMap[column][0];
                }
                
                this.toggleSearchInput();
                this.searchTarget.value = filterValue;
                this.toggleSearchButtons();
            }
        }
    }

    private handleRowUpdates = (update: RunLog) => {
        this.grid.updateRowData(update);
    };

    private createGrid() {
        this.grid = new Grid<RunLog>({
            key: 'id',
            allowSelection: false,
            showEmptyState: true,
            noRecordsTemplate: this.noRecordsTemplate,
            container: this.gridContainerTarget,
            columns: [
                {
                    headerTitle: 'Id',
                    field: 'id',
                    width: '100px',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        return `
                            <div class="px-4 pr-6 py-2 sm:py-4 mr-auto sm:mr-0 flex items-center">
                                <span class="ow-anywhere min-w-24 pr-1"> 
                                    ${run_log.attributes.id}
                                </span>
                            </div>
                        `;
                    }
                },
                {
                    headerTitle: 'Status',
                    field: 'status',
                    sortable: false,
                    width: '110px',
                    cellTemplate: (data: RunLog) => this.createStatusCellTemplate(data)
                },
                {
                    headerTitle: 'Created At',
                    field: 'created_at',
                    width: '200px',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        const date = new Date(run_log.attributes.created_at);
                        const options: Intl.DateTimeFormatOptions = {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                            timeZoneName: 'long'
                        };
                        const formattedDate = new Intl.DateTimeFormat(undefined, options).format(date);
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                                ${formattedDate}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'JEM',
                    field: 'loggable_id',
                    width: '100px',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        return `
                            <div class="px-4 pr-6 py-2 sm:py-4 mr-auto sm:mr-0 flex items-center">
                                <span class="ow-anywhere min-w-24 pr-1"> 
                                    ${run_log.attributes.loggable_id}
                                </span>
                            </div>
                        `;
                    }
                },
                {
                    headerTitle: 'Name',
                    field: 'name',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        return `
                        <div><a href="${run_log.attributes.view_url}" class="text-bkblue rounded" target="_blank">${run_log.attributes.name}</a></div>
                    `;
                    }
                },
                {
                    headerTitle: 'Root',
                    field: 'root_view_url',
                    sortable: false,
                    cellTemplate: (run_log) => {
                        if (run_log.attributes.root_view_url !== run_log.attributes.view_url) {
                            return `
                                <div><a href="${run_log.attributes.root_view_url}" class="text-bkblue rounded" target="_blank">${run_log.attributes.root_name}</a></div>
                            `;
                        }
                        return `
                            <div class="text-gray-500">-</div>
                        `;
                    }
                }
            ],
            pageOptions: {
                number: this.parmsObj.page.number,
                size: this.parmsObj.page.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page),
        });
    }

    private noRecordsTemplate = () => {
        let message = 'No records to show.';
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }


    reload(): void {
        let url = this.urlValue || '';
        let currentPageSize = this.parmsObj.page.size
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                this.grid.pageOptions.size = currentPageSize;
                this.grid.pageOptions.number = data.meta.pagination.current;
                this.grid.totalResultsCount = data.meta.pagination.records;
                this.grid.data = data.data;

                // Trigger the window resize event to adjust grid height
                // It runs resizeHandler function defined in app/views/tailwind/shared/_core_head.html.erb
                window.dispatchEvent(new Event('resize'));
            },
        });
    }

    createStatusCellTemplate(data: RunLog) {
        let template: string;
        template = '<div class="flex-1"></div>'
        const container = createElement(template);
        if (container) {
          Object.entries(data.attributes.status_counts_by_level).forEach(([level, counts]) => {
            const progressBar = this.createProgressBar(level, counts);
            container.appendChild(progressBar);
          });
        }
        return container;
    }

    createProgressBar(level: string, counts: StatusCounts): HTMLDivElement {
        const total = Object.values(counts).reduce((sum, count) => sum + count, 0);
        const progressBar = document.createElement('div');
        progressBar.className = 'progress-bar bg-white border border-bkblue';

        let formattedToolTipOutput = Object.entries(counts).map(([key, value]) => {
            return `${value} ${key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`;
        }).join('<br>');

        new Tooltip({
            element: progressBar,
            content: formattedToolTipOutput,
            class: 'whitespace-normal',
        });

        Object.entries(counts).forEach(([status, count]) => {
            const percentage = (count / total) * 100;
            const segment = document.createElement('div');
            segment.className = `progress-segment ${status}`;
            segment.style.width = `${percentage}%`
            progressBar.appendChild(segment);

            if (Object.keys(counts).length == 1){
                const label = document.createElement('span');
                label.className = 'progress-label';
                label.innerText = `${status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`;
                segment.appendChild(label);
            }
            else{
                const label = document.createElement('span');
                label.innerText = `${count}`;
                segment.appendChild(label);
            }
        });

        return progressBar;
    }

    animateButton(event: Event): void {
        const button = event.currentTarget as HTMLElement;
        button.classList.add('pulse');
        setTimeout(() => {
            button.classList.remove('pulse');
        }, 500); // Match the duration of the pulse animation
    }

    pageSize(e: Event): void {
        this.parmsObj.page.number = 1;
        e.preventDefault();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort.direction == 'asc' ? sort.column : '-' + sort.column
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.page = {
            number: page.number,
            size: page.size
        };
        this.reload();
    }

    disconnect() {
        super.disconnect();
    }

    toggleSearchInput(): void {
        const predicate = this.searchPredicateTarget.value;
        if (predicate) {
            this.searchValueTarget.classList.remove('hidden');
        } else {
            this.searchValueTarget.classList.add('hidden');
        }
        this.searchTarget.value = ''; // Clear the search input
        this.toggleSearchButtons(); // Update button visibility
    }

    togglePredicateInput(): void {
        const column = this.searchColumnTarget.value;
        if (column) {
            this.searchPredicateTarget.classList.remove('hidden');
            this.clearButtonTarget.classList.remove('hidden'); // Ensure clear button is visible
        } else {
            this.searchPredicateTarget.classList.add('hidden');
        }
        this.toggleSearchInput()
    }

    updatePredicates(): void {
        const column = this.searchColumnTarget.value;
        const predicateSelect = this.searchPredicateTarget;
        predicateSelect.innerHTML = '';

        const defaultOption = document.createElement('option');
        defaultOption.value = '';
        defaultOption.text = 'Select Predicate';
        predicateSelect.appendChild(defaultOption);

        if (this.columnPredicateMap[column]) {
            this.columnPredicateMap[column].forEach(predicate => {
                const option = document.createElement('option');
                option.value = predicate;
                option.text = this.formatPredicateText(predicate);
                predicateSelect.appendChild(option);
            });
        }

        this.togglePredicateInput();
        this.searchPredicateTarget.value = ''; // Clear the filter predicate
        this.searchTarget.value = ''; // Clear the search input
        this.searchValueTarget.classList.add('hidden'); // Hide the search container
    }

    private formatPredicateText(predicate: string): string {
        switch (predicate) {
            case 'i_cont': 
                return 'Contains value';
            case 'i_cont_any':
                return 'Contains any of values';
            case 'i_cont_all':
                return 'Contains all of values';
            case 'eq':
                return 'Equals';
            default:
                return predicate;
        }
    }

    private extractColumnAndPredicatefilter(filterKey: string): [column: string | null, predicate: string | null] {
        const match = filterKey.match(/filter\[(.+)\]/);
        if (!match) return [null, null];
    
        const parts = match[1].split('_');
        for (let i = parts.length - 1; i > 0; i--) {
            const predicate = parts.slice(i).join('_');
            const column = parts.slice(0, i).join('_');
            if (this.columnPredicateMap[column] && this.columnPredicateMap[column].includes(predicate)) {
                return [column, predicate];
            }
        }
    
        return [null, null];
    }

    buildQueryAndReload(): void {
        const column = this.searchColumnTarget.value;
        const predicate = this.searchPredicateTarget.value;
        const value = this.searchTarget.value.trim();
        if (!column || !predicate || !value) {
            if (this.parmsObj.filter) {
                delete this.parmsObj.filter;
            }
        }
        else {
            this.parmsObj.filter = {};
            this.parmsObj.filter[`${column}_${predicate}`] = value.replace(/\s+/g, ',').toString();
        }
        this.parmsObj.page.number = 1;
        this.reload();
        const query = stringifyParams(this.parmsObj);
        const url = `${this.urlValue}?${query}`;
        history.pushState(null, '', url);
        this.applyButtonTarget.classList.add('hidden');
    }

    applySearch(): void {
        this.buildQueryAndReload();
    }

    clearSearch(): void {
        this.searchColumnTarget.value = '';
        this.searchPredicateTarget.value = '';
        this.searchTarget.value = '';
        this.searchValueTarget.classList.add('hidden');
        this.searchPredicateTarget.classList.add('hidden');
        this.applyButtonTarget.classList.add('hidden');
        this.clearButtonTarget.classList.add('hidden');
        this.buildQueryAndReload();
    }

    toggleSearchButtons(): void {
        const column = this.searchColumnTarget.value;
        const predicate = this.searchPredicateTarget.value;
        const searchValue = this.searchTarget.value.trim();

        if (column && predicate && searchValue) {
            this.applyButtonTarget.classList.remove('hidden');
        } else {
            this.applyButtonTarget.classList.add('hidden');
        }

        if (column || predicate || searchValue) {
            this.clearButtonTarget.classList.remove('hidden');
        } else {
            this.clearButtonTarget.classList.add('hidden');
        }
    }
}

interface ParamsPageObj {
    number?: number
    size?: number
}

interface ParamsObj {
    search?: string;
    filter?: object;
    status?: string;
    sort?: string;
    page?: ParamsPageObj;
}

interface RunLogAttributes {
    id: number;
    state: string;
    created_at: string;
    name: string;
    loggable_id: string;
    view_url: string;
    root_view_url: string;
    root_name: string;
    status_counts_by_level: StatusCountsByLevel;
}

interface RunLog {
    attributes: RunLogAttributes;
}

interface StatusCounts {
    [status: string]: number;
}

interface StatusCountsByLevel {
    [level: string]: StatusCounts;
}
