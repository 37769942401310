import { Grid, Popup, Tooltip } from '@components';
import { GridRow } from '@components/grid';
import { PageOptions, SortOptions } from '@components/shared';
import { stringifyParams } from '@utils';
import ApplicationController from './application_controller';
import consumer from '../channels/consumer';

export default class extends ApplicationController {
    static targets = [
        'gridContainer',
        'typeFilter',
        'stateFilter',
        'entityFilter',
        'ignoreselectedaction',
        'unignoreselectedaction'
    ];

    declare gridContainerTarget: HTMLElement;
    declare typeFilterTarget: HTMLInputElement;
    declare stateFilterTarget: HTMLInputElement;
    declare entityFilterTarget: HTMLInputElement;
    declare ignoreselectedactionTarget: Element;
    declare hasIgnoreselectedactionTarget: boolean;
    declare unignoreselectedactionTarget: Element;
    declare hasUnignoreselectedactionTarget: boolean;

    static values = {
        confirmActionFormId: String,
        url: {
            type: String,
            default: '',
        },
    };

    declare urlValue: string;

    private grid: Grid<Notification>;
    private selectedData: GridRow<Notification>[];
    private parmsObj: ParamsObj = {};
    private disabled: boolean;
    private notificationSubscription: any;

    connect(): void {
        this.notificationSubscription = consumer.subscriptions.create(
            {
                channel: 'NotificationsChannel',
                user_id: this.element.dataset.userId,
            },
            {
                received: this.updateGrid.bind(this),
            }
        );
        let notificationParams = new URLSearchParams(window.location.search);
        this.parmsObj.page = {
            size: parseInt(notificationParams.get('page[size]')) || 30,
            number: parseInt(notificationParams.get('page[number]')) || 1
        };

        this.createGrid();
        // Apply default filters before reloading the data
        this.applyFilters({ detail: {} } as CustomEvent, true);
    }

    updateGrid(): void {
        this.applyFilters({ detail: {} } as CustomEvent, false);
    }

    private createGrid() {
        this.grid = new Grid<Notification>({
            key: 'id',
            allowSelection: true,
            showEmptyState: true,
            noRecordsTemplate: this.noRecordsTemplate,
            container: this.gridContainerTarget,
            isRowDisabled: (notification: Notification): boolean => {
                return (
                    notification.attributes.state == 'Resolved'
                );
            },
            columns: [
                {
                    type: 'checkbox',
                },
                {
                    headerTitle: 'status',
                    field: 'state',
                    width: '128px',
                    cellTemplate: (notification) => {
                        let classes = 'bg-red-100 text-red-800';
                        switch (notification.attributes.state) {
                            case 'Resolved':
                                classes = 'bg-bkgreen';
                                break;
                            case 'Pending':
                                classes = 'bg-secondary';
                                break;
                            case 'Ignored':
                                classes = 'bg-red-100 text-red-800';
                                break;
                            case 'deleted':
                                classes = 'bg-bkred-300';
                                break;
                        }
                        return `
                            <span class="overflow-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white ${classes} uppercase"> 
                                ${notification.attributes.state}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Created At',
                    width: '128px',
                    field: 'created_at',
                    sortable: true,
                    cellTemplate: (notification) => {
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                                ${notification.attributes.formatted_created_at}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Entity',
                    field: 'organization_name',
                    sortable: false,
                    cellTemplate: (notification) => {
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                                ${notification.attributes.organization_name}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Type',
                    field: 'type',
                    hideColumn: true,
                    sortable: false,
                    cellTemplate: (notification) => {
                        return `
                            <span class="ow-anywhere min-w-32"> 
                                ${notification.attributes.type}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Issue Details',
                    field: 'issue_details',
                    sortable: false,
                    cellTemplate: (notification) => {
                        return `
                            <span class="ow-anywhere min-w-32"> 
                                ${notification.attributes.issue_details}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Actions',
                    field: 'resolve_actions',
                    sortable: false,
                    cellTemplate: (notification) => {
                        let min_width = screen.width < 768 ? 'min-w-32' : '';
                        return `
                            <span class="ow-anywhere ${min_width}"> 
                                ${notification.attributes.resolve_actions}
                            </span>
                        `;
                    }
                }
            ],
            pageOptions: {
                number: this.parmsObj.page.number,
                size: this.parmsObj.page.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page),
            onSelectionChange: (selected: GridRow<Notification>[]) => this.selectionChanged(selected),
        });
    }

    private noRecordsTemplate = () => {
        let message = 'No records to show.';
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }

    reload(): void {
        let url = this.urlValue || '';
        let currentPageSize = this.parmsObj.page.size
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
            window.history.pushState({}, '', url);
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                this.grid.pageOptions.size = currentPageSize;
                this.grid.pageOptions.number = data.meta.pagination.current;
                this.grid.totalResultsCount = data.meta.pagination.records;
                this.grid.data = data.data;
            },
        });
    }

    pageSize(e: Event): void {
        this.parmsObj.page.number = 1;
        e.preventDefault();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort.direction == 'asc' ? sort.column : '-' + sort.column
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.page = {
            number: page.number,
            size: page.size
        };
        this.reload();
    }

    resetSelected(): void {
        this.grid.resetSelection();
    }

    disconnect() {
        this.notificationSubscription && this.notificationSubscription.unsubscribe();
        super.disconnect();
    }

    selectionChanged(selected: GridRow<Notification>[]): void {
        this.selectedData = selected;
        let distinct_states = this.selectedData.filter((x) => x.data).map((x) => x.data.attributes.state).filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        if (distinct_states.length == 1) {
            let filter_value = distinct_states[0]
            if (filter_value == 'Pending') {
                if (this.hasIgnoreselectedactionTarget) {
                    const anySelected = selected.length > 0;
                    if (anySelected) {
                        this.ignoreselectedactionTarget.classList.remove('hidden');
                    } else {
                        this.ignoreselectedactionTarget.classList.add('hidden');
                    }
                }
            }
            else if (filter_value == 'Ignored') {
                if (this.hasUnignoreselectedactionTarget) {
                    const anySelected = selected.length > 0;
                    if (anySelected) {
                        this.unignoreselectedactionTarget.classList.remove('hidden');
                    } else {
                        this.unignoreselectedactionTarget.classList.add('hidden');
                    }
                }
            }
        } else {
            this.unignoreselectedactionTarget.classList.add('hidden');
            this.ignoreselectedactionTarget.classList.add('hidden');
        }
    }

    ignore_selected(e: Event): void {
        if (!confirm('Are you sure you want to Ignore the selected items?')) {
            return;
        }
        const selectedIds = this.selectedData.filter((x) => x.data).map((x) => x.data.attributes.id);
        const target = e.currentTarget as HTMLButtonElement;
        target.disabled = true;
        fetch('notifications/perform_action?action_name=ignore_selected', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                ids: selectedIds,
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).then((res: any) => {
            if (parseInt((res.status / 100).toString()) == 2) {
                this.showAlert('Ignored selected Items', { type: 'success' });
                this.resetSelected();
                this.reload();
            } else if (res.status == 400) {
                this.showAlert('Failed to ignore selected items', { type: 'error' });
            }
            this.grid.resetSelection();
            target.disabled = false;
            this.ignoreselectedactionTarget.classList.add('hidden');
        });
    }

    unignore_selected(e: Event): void {
        if (!confirm('Are you sure you want to move selected items to pending state?')) {
            return;
        }
        const selectedIds = this.selectedData.filter((x) => x.data).map((x) => x.data.attributes.id);
        const target = e.currentTarget as HTMLButtonElement;
        target.disabled = true;
        fetch('notifications/perform_action?action_name=unignore_selected', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                ids: selectedIds,
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).then((res: any) => {
            if (parseInt((res.status / 100).toString()) == 2) {
                this.showAlert('Successfully moved selected items to pending state', { type: 'success' });
                this.resetSelected();
                this.reload();
            } else if (res.status == 400) {
                this.showAlert('Failed to move selected items to pending state', { type: 'error' });
            }
            this.grid.resetSelection();
            target.disabled = false;
            this.unignoreselectedactionTarget.classList.add('hidden');
        });
    }

    setColorToStateFilter(filter_value) {
        const statuses = [
            'notification-all',
            'notification-resolved',
            'notification-pending',
            'notification-ignored',
        ];
        statuses.forEach((status) => this.stateFilterTarget.classList.remove(status));
        this.stateFilterTarget.classList.add(`notification-${filter_value}`);
    }

    applyFilters(e: CustomEvent, initialLoad): void {
        // checking if the event is triggered by the page load or not
        if (!initialLoad) {
            this.parmsObj.page.number = 1;
        }
        delete this.parmsObj.filter;

        // state filter starts here
        let filter_value = this.stateFilterTarget.getAttribute('data-search-select-value')
        let statefilterObj = {
            // An ignored notification can be marked as resolved
            // Do not show resolved notification in ignored filter
            // Note: This filters should be same as notification.ignored?, notification.resolved? and notification.pending? methods
            'ignored': { 'ignored_at_not_null': '1', 'resolved_at_null': '1' },
            'resolved': { 'resolved_at_not_null': '1' },
            'pending': { 'ignored_at_null': '1', 'resolved_at_null': '1' }
        }

        if (statefilterObj[filter_value] != undefined) {
            this.parmsObj.filter = statefilterObj[filter_value];
            // posting the status filter value to make it easy to select correct value after reloading
            this.parmsObj.notifications_status = filter_value;
        }

        this.setColorToStateFilter(filter_value);
        // state filter ends here

        // entity filter starts here
        filter_value = this.entityFilterTarget.getAttribute('data-search-select-value')
        if (filter_value != 'all') {
            this.parmsObj.filter ||= {}
            this.parmsObj.filter['organization_id_eq'] = filter_value;
        }
        // entity filter ends here

        // type filter starts here
        filter_value = this.typeFilterTarget.getAttribute('data-search-select-value')
        if (filter_value != 'all') {
            this.parmsObj.filter ||= {}
            this.parmsObj.filter['name_eq'] = filter_value;
        }
        // type filter ends here

        this.resetSelected();
        this.reload();
    }
}

interface ParamsPageObj {
    number?: number
    size?: number
}

interface ParamsObj {
    filter?: object;
    status?: string;
    sort?: string;
    page?: ParamsPageObj;
    notifications_status?: string;
}
interface NotificationAttributes {
    id: number;
    state: string;
    formatted_created_at: string;
    organization_name: string;
    type: string;
    issue_details: string;
    resolve_actions: string;
}

interface Notification {
    attributes: NotificationAttributes;
}
